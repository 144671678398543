<div class="container">
    <div class="sidebar">
        <div class="title">Activity Dashboard</div>
        <button mat-flat-button class="option-button">
            <mat-icon>view</mat-icon>
            Viewers
        </button>
        <button mat-flat-button class="option-button">
            <mat-icon>trend</mat-icon>
            Viewer Trend
        </button>
    </div>
    <div class="content">
        <button mat-icon-button><mat-icon>close</mat-icon></button>
        This is content

        <table>
            <tr>
                <th>Name</th>
                <th>Last Viewed</th>
            </tr>
            <tr>
                <td></td>
                <td></td>
            </tr>
        </table>

        <div class="content-footer">This is the footer</div>
    </div>
</div>
