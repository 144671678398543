<div class="container">
  <div class="feature">
    <img ngSrc="assets/img/404_fail_whale.webp" width="512" height="512" (click)="goHome()" style="cursor:pointer;">
    <div class="message">
      <p>Something went wrong. This link doesn't appear to work!</p>
      <p>Better report to <a href="https://ultimatecribbage.slack.com/archives/C059L0GD988" target="_blank">#atlas-users</a></p>
    </div>
    <div class="url">{{url}}</div>
  </div>
</div>


