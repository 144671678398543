import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Location} from '@angular/common';
import {DashboardService} from '../../../services/dashboard.service';
import {firstValueFrom} from 'rxjs';
import {AuthorizationService} from "../../../authorization.service";

@Component({
  selector: 'app-dashboard-viewer',
  templateUrl: './dashboard-viewer.component.html',
  styleUrls: ['./dashboard-viewer.component.css']
})
export class DashboardViewerComponent implements OnInit {
  isReady = false;
  dashboardId: string;
  namespace: string;
  parameters: any;
  dashboard: any;

  constructor(private activatedRoute: ActivatedRoute,
              private authorizationService: AuthorizationService,
              private location: Location,
              private dashboardService: DashboardService) {
    console.log('Dashboard viewer');
  }

  async ngOnInit() {
    const [params, _] = await Promise.all([
      firstValueFrom(this.activatedRoute.params),
      this.authorizationService.waitForAuthorization(),
    ]);

    this.dashboardId = params.dashboardId;
    this.namespace = params.namespace;

    this.dashboard = await this.dashboardService.getDashboard(this.namespace, this.dashboardId);
    console.log(this.dashboard);
    this.parameters = this.dashboard.parameters;

    this.isReady = true;
  }

  showActivity() {
    alert('Not implemented');
  }

  edit() {
    const url = `https://us-west-2.quicksight.aws.amazon.com/sn/dashboards/${this.dashboardId}`;
    window.open(url, '_blank');
  }
  close() {
    this.location.back();
  }
}
