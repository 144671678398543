import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as Auth from '@aws-amplify/auth';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      try {
        await Auth.getCurrentUser();
        return true;
      } catch (err) {
        return this.router.parseUrl('login');
      }
    }
}
