<mat-sidenav-container class="container" autosize="true">
  <mat-sidenav #drawer mode="side" opened role="navigation">
    <div fxLayout="column" fxFill>
      <div style="text-align: center">
        <img ngSrc="assets/img/wc-logo-blue-round-256.png" style="width: 96px; height: 96px;" height="256" width="256">
      </div>
      <mat-nav-list>
          <a mat-list-item routerLink='' routerLinkActive="active-route-link" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon class="nav-icon" mat-list-icon>home</mat-icon> Home
          </a>
          <a mat-list-item routerLink='/data' routerLinkActive="active-route-link">
            <mat-icon class="nav-icon">bar_chart</mat-icon>
            Data
          </a>
          <a mat-list-item routerLink='/finance' routerLinkActive="active-route-link">
            <mat-icon class="nav-icon">account_balance</mat-icon>
            Finance
          </a>
          <a mat-list-item routerLink='/growth' routerLinkActive="active-route-link">
            <mat-icon class="nav-icon">trending_up</mat-icon>
            Growth
          </a>
        <a mat-list-item routerLink='/games' routerLinkActive="active-route-link">
          <mat-icon class="nav-icon">gamepad</mat-icon>
          Games
        </a>
        <a mat-list-item routerLink='/wildcloud' routerLinkActive="active-route-link">
          <mat-icon fontSet="material-icons-two-tone">cloud</mat-icon>
          WildCloud
        </a>
      </mat-nav-list>
      <div fxFlex></div>
      <app-build-identifier></app-build-identifier>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z6">
      <mat-toolbar-row fxLayout="row">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="accent">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div fxFlex></div>
        <app-user-profile-widget></app-user-profile-widget>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
