import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";

type DialogMode = 'create' | 'edit';
@Component({
  selector: 'app-create-dashboard-dialog',
  templateUrl: './create-dashboard-dialog.component.html',
  styleUrls: ['./create-dashboard-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatInputModule, MatFormFieldModule]
})
export class CreateDashboardDialogComponent implements OnInit {

  name: string;
  dashboardId: string;
  namespace = 'default';

  mode: DialogMode = 'create';

  constructor(private dialogRef: MatDialogRef<CreateDashboardDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {name: string, dashboardId: string, namespace: string}) { }

  ngOnInit(): void {
    this.name = this.data.name;
    this.dashboardId = this.data.dashboardId;
    this.namespace = this.data.namespace;

    if (this.name && this.dashboardId && this.namespace) {
      this.mode = 'edit';
    }
  }

  create() {
    this.dialogRef.close({action: 'create', name: this.name, dashboardId: this.dashboardId, namespace: this.namespace});
  }

  save() {
    this.dialogRef.close({action: 'save', name: this.name, dashboardId: this.dashboardId, namespace: this.namespace});
  }

  remove() {
    if (confirm(`Are you sure you want to delete this dashboard?`) ) {
      this.dialogRef.close({action: 'delete'});
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  get isCreateMode(): boolean {
    return this.mode === 'create';
  }

  get isEditMode(): boolean {
    return this.mode === 'edit';
  }

  get isCreateEnabled(): boolean {
    if (!this.name || !this.dashboardId || !this.namespace) {
      return false;
    }

    return true;
  }

  get isSaveEnabled(): boolean {
    if (!this.name || !this.dashboardId || !this.namespace) {
      return false;
    }

    if (this.name === this.data.name && this.dashboardId === this.data.dashboardId && this.namespace === this.data.namespace) {
      return false;
    }

    return true;
  }
}

