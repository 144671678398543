<div class="alert-body">

    <div class="alert-title" mat-dialog-title>
        <mat-icon>{{icon_name}}</mat-icon>
        {{this.data.title}}
    </div>
    <mat-dialog-content>
        <div class="alert-message">
            {{this.data.message}}
        </div>
        <div *ngIf="this.data.stack" style="overflow: scroll">
            <div *ngFor="let entry of stack">{{entry}}</div>
        </div>
        <div *ngIf="this.data.link" class="alert-footer">
            <div (click)="openUrl(this.data.link.url)">
                <mat-icon>link</mat-icon>
                <a href="this.data.link.url">{{this.data.link.title}}</a>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" class="alert-actions">
        <div fxFlex></div>
        <button *ngFor="let title of buttons"
                mat-flat-button
                class="alert-action"
                (click)="close(0)">{{title}}</button>
        <div fxFlex></div>
    </mat-dialog-actions>
</div>
