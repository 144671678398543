
import {Injectable} from '@angular/core';

export interface IEditPageComponent {
  readonly isDirty: boolean;
}

@Injectable()
export class DirtyDeactivateGuard  {
  canDeactivate(component: IEditPageComponent): boolean {
    if (component.isDirty) { return confirm("You have unsaved changes that will be lost. Do you really want to leave?"); }
    return true;
  }
}
