import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from '@angular/material/icon';

export interface ActivityReportData {
  url: string;
}

@Component({
  selector: 'activity-view-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './activity-view-dialog.component.html',
  styleUrls: ['./activity-view-dialog.component.scss']
})
export class ActivityViewDialog implements OnInit {
  constructor(private dialogRef: MatDialogRef<ActivityViewDialog>,
              @Inject(MAT_DIALOG_DATA) public data: ActivityReportData) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    // TODO: load activity report
  }


  close() {
    this.dialogRef.close();
  }
}
