import {Injectable, OnInit} from '@angular/core';
import {AuthorizationService} from "../authorization.service";
import {DynamoDBDocumentClient} from "@aws-sdk/lib-dynamodb";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";

@Injectable({
  providedIn: 'root'
})
export class DynamodbService implements OnInit {
  client: DynamoDBDocumentClient;

  constructor(private authorizationService: AuthorizationService) { }

  async ngOnInit() {
  }

  async getClient() {
    const credentials = this.authorizationService.currentCredentials;
    return DynamoDBDocumentClient.from(new DynamoDBClient({region: 'us-west-2', credentials}));
  }

}
