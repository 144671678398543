<div class="alert-body">
    <div class="alert-title" mat-dialog-title>
        <mat-icon>{{icon_name}}</mat-icon>
        <div>{{this.data.title}}</div>
    </div>
    <mat-dialog-content>
        <div class="alert-message">
            {{this.data.message}}
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" class="alert-actions">
        <div fxFlex></div>
        <button *ngFor="let title of buttons; index as i"
                mat-flat-button
                class="alert-action"
                (click)="close(i)" [color]="color(i)">{{title}}</button>
        <div fxFlex></div>
    </mat-dialog-actions>
</div>
