<div style="display: flex; flex-direction: row; align-items: center">
    <div (click)="close()">Dashboards</div>
    <div style="padding: 5px;">&gt;</div>
    <div>{{dashboard.name}}</div>
    <div style="flex-grow: 1"></div>
    <button mat-icon-button (click)="showActivity()"><mat-icon>visibility</mat-icon></button>
    <button mat-icon-button (click)="edit()" matTooltip><mat-icon>edit</mat-icon></button>
    <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<div *ngIf="isReady" style="width: 100%; height: 100%">
  <app-quicksight-dashboard [namespace]="namespace" [dashboardId]="dashboardId" [parameters]="parameters"></app-quicksight-dashboard>
</div>
<app-spinner *ngIf="!isReady"></app-spinner>
