@Component({
  selector: 'status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.css']
})
export class StatusIndicatorComponent {
  @Input() status: string;
  @Input() info: string;

  get iconName() {
    let icon = 'help';

    switch (this.status) {
      case 'initializing':
      case 'starting':
      case 'started':
        icon = 'pending';
        break;
      case 'running':
        icon = 'cached';
        break;
      case 'error':
        icon = 'error';
        break;
      case 'success':
        icon = 'check_circle';
        break;
    }

    return icon;
  }

  get style(): string {
    return `color:${this.iconColor}; cursor: pointer`
  }

  get iconColor() {
    let color = 'gray';
    switch (this.status) {
      case 'started':
        color = 'blue';
        break;
      case 'running':
        color = 'blue';
        break;
      case 'error':
        color = 'red';
        break;
      case 'success':
        color = 'green';
        break;
    }
    return color;
  }
}

import {Component, Input} from '@angular/core';
