import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DashboardUser} from "../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  dataSource: MatTableDataSource<DashboardUser>;
  displayedColumns: string[] = ['name'];

  constructor(private userService: UserService) { }

  async ngOnInit() {
    const users = await this.userService.list();
    this.dataSource = new MatTableDataSource<DashboardUser>(Object.values(users));
  }
}
