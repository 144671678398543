<mat-tab-group>
  <mat-tab label="My Settings">
    <h1>Some tab content</h1>
    <p>...</p>
    <button mat-button (click)="runQueryReport()">Run Query</button>
  </mat-tab>
  <mat-tab label="Users">
    <!-- <app-users></app-users> -->
  </mat-tab>
</mat-tab-group>
