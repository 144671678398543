<div class="container" *ngIf="!groups || groups.length === 0">
  <button mat-stroked-button (click)="addGroup()"><mat-icon>add</mat-icon>Add Filter</button>
</div>
<div class="container" *ngIf="groups && groups.length > 0">
  <div *ngFor="let group of groups" class="group">
    <div style="display: flex; flex-direction: row">
      <div *ngIf="group.filters.length > 1" class="condition"><span style="transform: rotate(-90deg)">AND</span></div>
      <div class="filters">
        <div *ngFor="let filter of group.filters" class="filter" [class.filter-inset]="group.filters.length > 1">
          <mat-form-field subscriptSizing="dynamic" >
            <mat-label>Rule</mat-label>
            <mat-select (selectionChange)="setFilterRuleId(filter, $event.source.value)" [(value)]="filter.ruleId" [disabled]="readOnly">
              <mat-option *ngFor="let rule of rules" value="{{rule.id}}">{{rule.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="ruleHasOperators(filter.ruleId)" subscriptSizing="dynamic">
            <mat-label>Operator</mat-label>
            <mat-select (selectionChange)="setFilterOperator(filter, $event.source.value)" [(value)]="filter.operator" [disabled]="readOnly">
              <mat-option *ngFor="let operator of operatorsForRule(filter.ruleId)" [value]="operator">{{nameForOperator(operator)}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="ruleHasValues(filter.ruleId)" subscriptSizing="dynamic">
            <mat-label>Value</mat-label>
            <mat-select (selectionChange)="setFilterValue(filter, $event.source.value)" [value]="filter.value" [disabled]="readOnly">
              <mat-option *ngFor="let value of valuesForRule(filter.ruleId)" [value]="value">{{value}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="typeOfRule(filter.ruleId, 'countryList')" subscriptSizing="dynamic">
            <mat-label>Value</mat-label>
            <mat-select (selectionChange)="setFilterValue(filter, $event.source.value)" [value]="wrapInArrayIfNeeded(filter.value)" [multiple]="true" [formControl]="countriesMultiCtrl">
              <mat-option>
                <ngx-mat-select-search
                        placeholderLabel="Search..."
                        noEntriesFoundLabel="No countries found"
                        [formControl]="countriesFilterCtrl"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-select-trigger>{{wrapInArrayIfNeeded(filter.value).join(',')}}</mat-select-trigger>
              <mat-option *ngFor="let countryCode of getCountryCodes()" [value]="countryCode">{{getCountryName(countryCode)}} [{{countryCode}}]</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox *ngIf="typeOfRule(filter.ruleId, 'bool')" [checked]="filter.value" (change)="setFilterValue(filter, $event.checked)" [disabled]="readOnly">{{getRuleById(filter.ruleId).name}}</mat-checkbox>

          <mat-form-field *ngIf="typeOfRule(filter.ruleId, 'string') || typeOfRule(filter.ruleId, 'bundleVersion')" subscriptSizing="dynamic">
            <mat-label>Value</mat-label>
            <input matInput [(ngModel)]="filter.value" (change)="valueChanged()" [disabled]="readOnly">
          </mat-form-field>

          <mat-form-field *ngIf="typeOfRule(filter.ruleId, 'number')" subscriptSizing="dynamic">
            <mat-label>Value</mat-label>
            <input matInput type="number" [(ngModel)]="filter.value" (change)="valueChanged()" [disabled]="readOnly">
          </mat-form-field>

          <mat-form-field *ngIf="typeOfRule(filter.ruleId, 'date')" subscriptSizing="dynamic">
            <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="filter.value" (dateChange)="datePickerValueChanged(filter)" [disabled]="readOnly">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <div style="flex-grow: 1"></div>
          <button mat-button (click)="addFilter(group)" *ngIf="isLastFilterInGroup(group, filter)" matTooltip="AND" [hidden]="readOnly"><mat-icon>add</mat-icon></button>
          <button mat-button (click)="deleteFilter(group, filter)" matTooltip="Remove" [hidden]="readOnly"><mat-icon>delete</mat-icon></button>
        </div>
      </div>
    </div>
    <div *ngIf="groups.indexOf(group) !== groups.length-1" class="or-keyword">OR</div>
  </div>
  <div style="display: flex; justify-content: center; width: 100%; margin: 2px;">
    <button mat-stroked-button (click)="addGroup()" [hidden]="readOnly"><mat-icon>add</mat-icon> OR</button>
  </div>
</div>
