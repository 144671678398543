import {Component, Input, OnInit} from '@angular/core';
import {createEmbeddingContext} from "amazon-quicksight-embedding-sdk";
import {DashboardService} from "../../../services/dashboard.service";
import {CommonModule} from "@angular/common";
import {FileTicketButtonComponent} from "../../ui/file-ticket-button/file-ticket-button.component";
import {SpinnerComponent} from "../../ui/spinner/spinner.component";
import {AnalyticsService} from "../../../services/analytics.service";

@Component({
  standalone: true,
  selector: 'app-quicksight-dashboard',
  templateUrl: './quicksight-dashboard.component.html',
  styleUrls: ['./quicksight-dashboard.component.scss'],
  imports: [CommonModule, FileTicketButtonComponent, SpinnerComponent],
})
export class QuicksightDashboardComponent implements OnInit {
  @Input() dashboardId: string;
  @Input() namespace: string;
  @Input() parameters: any;
  @Input() contentOptions: any;

  isReady = false;

  constructor(private dashboardService: DashboardService,
              private analyticsService: AnalyticsService) { }

  async ngOnInit() {
    const url = await this.getDashboardUrl();
    await this.loadDashboard(url);
    this.analyticsService.trackEvent('dashboard_viewed', this.dashboardId, 'dashboards');
  }

  async getDashboardUrl() {
    let url = await this.dashboardService.getQuicksightDashboard(this.namespace, this.dashboardId);

    if (this.parameters) {
      const params = Object.keys(this.parameters).map(key => `p.${key}=${this.parameters[key]}`);

      url = `${url}#${params.join('&')}`;

      console.log('Transformed:', url);
    }

    return url;
  }

  async loadDashboard(embeddedURL: string) {
    const me = this;
    function onDashboardUpdated(event : any, metadata: any) {
      // FRAME_LOADED
      // FRAME_MOUNTED
      // FRAME_STARTED

      if (event.eventName === 'FRAME_LOADED') {
        me.isReady = true;
      }
    }

    // const containerDiv = document.getElementById("dashboardContainer");
    const embeddingContext = await createEmbeddingContext({onChange: onDashboardUpdated});

    const {
      embedDashboard,
      // embedVisual,
      // embedConsole,
      // embedQSearchBar,
    } = embeddingContext;

    const frameOptions = {
      url: embeddedURL,
      container: "#dashboardContainer",
      // height: "850px",
      width: "100%",
      height: "100%",
    };

    const contentOptions = this.contentOptions || {};

    console.warn('Setting content options', contentOptions);

    await embedDashboard(frameOptions, contentOptions);
  }

  get ticketDescription(): string {
    return `
Dashboard id: ${this.dashboardId}
Parameters: ${JSON.stringify(this.parameters)}
ContentOptions: ${JSON.stringify(this.contentOptions || {})}
    `;
  }
}
