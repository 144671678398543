import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../application.service';
import {ProgressDialogComponent} from '../modules/ui/progress-dialog/progress-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private applicationService: ApplicationService,
              public dialog: MatDialog) { }

  ngOnInit() {
  }
  generateRetentionSQLReport(testId: string, cohorts: {}) {
    let columns = '';

    // tslint:disable-next-line:forin
    for (const cohortName in cohorts) {
      columns += `,\n      SUM(CASE WHEN RS.cohortId = '${cohorts[cohortName]}' THEN RS.value END) as '${cohortName}'`;
    }

    const template = `
    SELECT RS.day ${columns}
    FROM (
      select MC.cohortId as cohortId, MC.day as day, value/(select count(cohortId) from metrics_cohorts where day = 0 AND cohortId = MC.cohortID and metric = 'sessions') as value
      FROM (
        select cohortId, day, count(value) as value from metrics_cohorts
        where testId = '${testId}'
          and metric = 'sessions'
        group by cohortId, day
        order by cohortId, day
      ) MC
    ) RS
    GROUP BY day;`;

    return template;
  }

  generateRevenueSQLReport(testId: string, cohorts: {}) {
    let columns = '';

    // tslint:disable-next-line:forin
    for (const cohortName in cohorts) {
      columns += `,\n      SUM(CASE WHEN RS.cohortId = '${cohorts[cohortName]}' THEN RS.value END) as '${cohortName}'`;
    }

    const template = `
    SELECT RS.day ${columns}
    FROM (
      select MC.cohortId as cohortId, MC.day as day, value/(select count(cohortId) from metrics_cohorts where day = 0 AND cohortId = MC.cohortID and metric = 'sessions') as value
      FROM (
        select cohortId, day, sum(value) as value from metrics_cohorts
        where testId = '${testId}'
          and metric = 'revenue'
        group by cohortId, day
        order by cohortId, day
      ) MC
    ) RS
    GROUP BY day;`;

    return template;
  }

  runQueryReport() {
    const sql = this.generateRevenueSQLReport('5fb8dd9f-1d33-4d7a-8b1a-202e99fa67ca', {
      Default: '1d6263c4-7d5f-4f07-9e90-78cb01d188b8',
      A: 'daefeaf1-1993-48df-8df5-5ee48ee468ac',
      B: 'cc17d880-6e67-466d-8f1e-7be4c6e56dc0',
      C: 'f8958775-b95b-4923-a7d5-5e2a7d2744fb',
      D: '5cd04357-b390-479d-a36d-cf9c6f7c215e',
    });

    console.warn(sql);

    this.applicationService.runReportingQuery(sql)
        .then(results => {
          console.log(results);
          alert(`Total metric_cohorts records: ${results[0]['count(*)']}`);
        })
        .catch(err => {
          console.error(err);
          alert(err);
        });
  }
}
