import { Injectable } from '@angular/core';
import * as API from '@aws-amplify/api-rest'
import {RestApiOptionsBase} from "@aws-amplify/api-rest/src/types";

export class ApiWrapper
{
  constructor(private apiName: string, private basePath: string = '') {}

  async get<T>(path: string, options?: RestApiOptionsBase | undefined){

    if (this.basePath) {
      path = `${this.basePath}/${path}`;
    }

    const response = await API.get({apiName: this.apiName, path, options}).response;

    return await response.body.json() as T;
  }
  async post<T>(path: string, options: RestApiOptionsBase){
    try {
      const response = await API.post({apiName: this.apiName, path, options}).response;

      if (response.body) {
        return await response.body.json() as T;
      }
    } catch (err) {
      throw new Error(`Failed to execute post on ${this.apiName}. ${err}`);
    }
  }
  async put<T>(path: string, options: RestApiOptionsBase){
    const response = await API.put({apiName: this.apiName, path, options}).response;

    if (response.body) {
      return await response.body.json() as T;
    }
  }
  async del(path: string, options?: RestApiOptionsBase){
    return API.del({apiName: this.apiName, path, options}).response;
  }
  async head(path: string, options?: RestApiOptionsBase){
    return API.head({apiName: this.apiName, path, options}).response;
  }
  async patch<T>(path: string, options: RestApiOptionsBase){
    const response = await API.patch({apiName: this.apiName, path, options}).response;

    if (response.body) {
      return await response.body.json() as T;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {
  data = new ApiWrapper('data');
  quicksightDashboard = new ApiWrapper('quicksightDashboard');
  reportingQuery = new ApiWrapper('reportingQuery');
  campaigns = new ApiWrapper('campaigns');
  athena = new ApiWrapper('athena');

  constructor() { }
}
