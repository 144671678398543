import { Component, OnInit } from '@angular/core';
import {STS} from "@aws-sdk/client-sts";
import {Router} from "@angular/router";
import {Access, AuthorizationService, Feature} from "../../../authorization.service";
import {hashMd5} from "../../../data-model/utilities/experiments";
import {LocalStorageService} from "../../../services/local-storage.service";
import {createJiraUrl} from "../../../data-model/utilities/jira";

@Component({
  selector: 'app-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.css']
})
export class UserProfileWidgetComponent implements OnInit {

  iconUrl: string;

  constructor(private router: Router,
              private authorizationService: AuthorizationService,
              private localStorageService: LocalStorageService,
              ) { }

  async ngOnInit() {
    await this.loadIconUrl();
  }

  get userName(): string {
    let userName = '';
    const user = this.authorizationService.userAttributes;

    if (user) {
      userName = user.email;
    }
    return userName;
  }

  get credentials() {
    return this.authorizationService.currentCredentials;
  }

  get currentUser() {
    return this.authorizationService.currentAuthenticatedUser;
  }

  async reportIssue() {
    const url = createJiraUrl({
      description: `[YOUR DESCRIPTION HERE]

=============================Context Info=============================
Source url: ${window.location}
${window.navigator.userAgent}
    `,
      summary:  'Feedback: [Your description here]',
      reporter: this.authorizationService.userAttributes.email,
      labels: 'UserReport',
      issuetype: '3',
      pid: '11954', // ATL
    });

    window.open(url, '_blank');
  }

  async showProfile() {
    let profile = '';

    const credentials = this.credentials;
    const user = this.currentUser;

    console.log(user);

    const identity = await new STS(credentials).getCallerIdentity();
    profile += `User name: ${JSON.stringify(identity)}\n`;
    console.log(identity);

    alert(profile);
  }

  async loadIconUrl() {
    let url = await this.localStorageService.get<string>('gravatar.url');

    if (url) {
      this.iconUrl = url;
    }

    await this.authorizationService.waitForAuthorization();

    const newUrl = `https://www.gravatar.com/avatar/${hashMd5(this.authorizationService.userAttributes.email)}`;

    if (!url || url !== newUrl) {
      this.localStorageService.set('gravatar.url', newUrl);
      this.iconUrl = newUrl;
    }
  }

  async logoutUser() {
    try {
      // await Auth.signOut();
      await this.router.navigate(['/login']);
    } catch (e) {
      alert(e);
    }
  }

  get isAdmin(): boolean {
    return this.authorizationService.hasAccess(Feature.UserManagement, Access.Read);
  }

}
