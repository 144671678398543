import {
  DashboardRole,
  DashboardUser, GetDashboardUserRequest, GetDashboardUserResponse,
  InviteDashboardUserRequest
} from "../../../wildcard-dashboard-common/src/models/dashboard-user";
import {ApiWrapper} from "../services/dashboard-api.service";

const users = new ApiWrapper('users');
const roles = new ApiWrapper('roles');

export class UsersAPI {
  public static async inviteUser(request: InviteDashboardUserRequest) {
    return users.put('', {body: request as any});
  }

  public static async getUser(id: string): Promise<DashboardUser> {
    if (!id) {
      throw new Error('Missing id');
    }

    return users.get(`/${id}`);
  }

  public static async listUsers(): Promise<DashboardUser[]> {
    return users.get(``);
  }

  public static async deleteUser(id: string) {
    return users.del(`/${id}`);
  }

  public static async updateUser(user: DashboardUser) {
    return await users.post(`/${user.id}`, {body: user as any}) as DashboardUser;
  }

  /*
  *
  * Roles
  *
  */

  public static async listRoles() {
    return roles.get('');
  }
}
