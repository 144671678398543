import { Component, OnInit } from '@angular/core';
import {Hub} from 'aws-amplify/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router) {
  }

  async ngOnInit() {
    Hub.listen( 'auth', (data) => {
      switch (data?.payload?.event) {
        case 'signedIn':
          this.router.navigate(['']).then(() => {});
          break;
      }
    });
  }
}
