import {Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[contentEditable]',
})
export class ContentEditableDirective {

  private appContentEditableModelValue: any;
  private allowedCharacterFilterValue: string = null;
  private allowedCharacterFilterRegex: RegExp = null;

  @Output() contentEditableChange ?= new EventEmitter();

  @Input() get contentEditable() {
    return this.appContentEditableModelValue;
  }

  @Input() get allowedCharacterFilter() {
    return this.allowedCharacterFilterValue;
  }

  set allowedCharacterFilter(value: string) {
    this.allowedCharacterFilterValue = value;
  }

  set contentEditable(val: any) {
    this.appContentEditableModelValue = val;
    this.contentEditableChange.emit(this.appContentEditableModelValue);
    this.refreshView();
  }

  constructor(private elementRef: ElementRef) {
    if (!elementRef.nativeElement.isContentEditable) {
      elementRef.nativeElement.contentEditable = 'true';
      elementRef.nativeElement.setAttribute('contentEditable', 'true');
    }
  }

  @HostListener('keypress')
  private filterInput() {
    // TODO: something
  }

  @HostListener('blur')
  private updateValue() {
    const value = this.elementRef.nativeElement.innerText;
    this.contentEditable = value;
    this.contentEditableChange.emit(value);
  }

  private refreshView() {
    console.log(`refreshView: ${this.contentEditable}`);
    this.elementRef.nativeElement.innerText = this.contentEditable;
  }
}
