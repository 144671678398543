export interface JiraTicketParameters {
    description: string;
    summary:  string;
    reporter: string;
    labels: string;
    issuetype: string;
    pid: string;
}

export function createJiraUrl(parameters: JiraTicketParameters) {
    // Based on the JIRA doc page: https://confluence.atlassian.com/jirakb/how-to-create-issues-using-direct-html-links-in-jira-server-159474.html

    const baseUrl = 'https://wildcardgames.atlassian.net/secure/CreateIssueDetails!init.jspa';

    const paramList: string[] = [];
    for( const key of Object.keys(parameters)) {
        paramList.push(`${key}=${encodeURIComponent(parameters[key])}`)
    }

    return `${baseUrl}?${paramList.join('&')}`;
}
