import { NgModule } from '@angular/core';

import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {RuleEditorComponent} from "./rule-editor/rule-editor.component";
import {MatIconModule} from "@angular/material/icon";
import {BuildIdentifierComponent} from "./build-identifier/build-identifier.component";
import {PortalUserAvatarComponent} from "./portal-user-avatar/portal-user-avatar.component";
import {ProgressDialogComponent} from "./progress-dialog/progress-dialog.component";
import {UserProfileWidgetComponent} from "./user-profile-widget/user-profile-widget.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ContentEditableDirective} from "./directives/content-editable-model.directive";
import { CodeEditorComponent } from './code-editor/code-editor.component';
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import { EditableTextFieldComponent } from './editable-text-field/editable-text-field.component';
import { SmallIconButtonComponent } from './small-icon-button/small-icon-button.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {AlertComponent} from "./alert/alert.component";
import {AlertSelectComponent} from "./alert-select/alert-select.component";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  declarations: [
    RuleEditorComponent,
    BuildIdentifierComponent,
    PortalUserAvatarComponent,
    ProgressDialogComponent,
    UserProfileWidgetComponent,
    ContentEditableDirective,
    CodeEditorComponent,
    EditableTextFieldComponent,
    SmallIconButtonComponent,
    StatusIndicatorComponent,
    AlertComponent,
    AlertSelectComponent,
  ],
    exports: [
        RuleEditorComponent,
        BuildIdentifierComponent,
        PortalUserAvatarComponent,
        ProgressDialogComponent,
        UserProfileWidgetComponent,
        ContentEditableDirective,
        CodeEditorComponent,
        EditableTextFieldComponent,
        SmallIconButtonComponent,
        StatusIndicatorComponent,
    ],
  imports: [
    // Angular Imports
    CommonModule,
    DragDropModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    // Material Imports
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,

    // External modules
    NgOptimizedImage,
    NgxMatSelectSearchModule
  ]
})
export class CommonUiModule { }
