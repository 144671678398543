import { v4 as uuid } from 'uuid';
import moment from "moment/moment";

const { DateTime } = require('luxon');
const shortUUID = require('short-uuid');

export class Utilities {

    static createGUID(): string {
        return uuid();
    }

    static createShortGUID(): string {
        return shortUUID.generate();
    }

    static getTimeStamp(opts?: any): string {
        let date = DateTime.utc();

        if (opts) {
            date = date.plus(opts);
        }

        return date.toISO();
    }

    static copy<T=any>(obj: T): T {
        return JSON.parse(JSON.stringify(obj)) as T;
    }

    static async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getEnumValues(e): string[] {
        return Object.keys(e).map(i => e[i]).filter(i => isNaN(i));
    }

    static isValidJson(value: string) : boolean {
        let isValidJson = true;
        try {
            JSON.parse(value);
        } catch {
            isValidJson = false;
        }
        return isValidJson;
    }

    static arrayToMap(a: any[], keyName: string) {
        const m = {};
        a.forEach(e => m[e[keyName]] = e);
        return m;
    }
}

export class ArrayUtils {
    static isNullOrEmpty(array: any[]): boolean {
        return !array || array.length === 0;
    }
}

export function daysSinceDate(date): number {
    return Math.ceil((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)) + 1;
}

export function addDaysToDate(date: Date, days: number): Date {
    const d = new Date(date);

    d.setDate(date.getDate() + days);

    return d;
}

export function standardDateFormat(date): string {
    //return luxon.DateTime.fromISO(date).toFormat('yyyy-MM-dd');
    return moment(date).utc().format('YYYY-MM-DD');
}
