<div class="alert-title">{{data.title}}</div>
<mat-dialog-content>
    <div class="alert-message">{{data.message}}</div>
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: center">
        <input matInput [(ngModel)]="value" style="width: 200px">
    </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; flex-direction: row">
    <div style="flex-grow: 1"></div>
    <button mat-stroked-button (click)="cancel()" class="alert-action">{{buttons[0]}}</button>
    <div style="flex-grow: 1"></div>
    <button mat-flat-button (click)="confirm()" color="primary" class="alert-action" [disabled]="!canConfirm">{{buttons[1]}}</button>
    <div style="flex-grow: 1"></div>
</mat-dialog-actions>
