import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, NgOptimizedImage]
})
export class PageNotFoundComponent implements OnInit {
  url: string;

  constructor(private router: Router) {}
  async ngOnInit() {
    this.url = this.router.url;
  }

  async goHome() {
    return this.router.navigate(['/']);
  }
}
