import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cross-promotion',
  templateUrl: './cross-promotion.component.html',
  styleUrls: ['./cross-promotion.component.css']
})
export class CrossPromotionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
