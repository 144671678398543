import {Component, HostListener, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {AlertPromptData} from "../alert.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";

@Component({
  selector: 'alert-prompt',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './alert-prompt.component.html',
  styleUrls: ['./alert-prompt.component.scss']
})
export class AlertPromptComponent {
  buttons = ['Cancel', 'OK'];
  value = '';

  @HostListener('window:keyup.esc') onKeyUp() {
    this.cancel();
  }

  constructor(private dialogRef: MatDialogRef<AlertPromptComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AlertPromptData) {

    dialogRef.disableClose = true;
    this.buttons = data.buttons || this.buttons;
  }

  get canConfirm() : boolean {
    if (this.data.validator) {
      return this.data.validator(this.value);
    }

    return this.value !== '';
  }

  confirm() {
    this.dialogRef.close(this.value);
  }

  cancel() {
    this.dialogRef.close();
  }
}
