import {Md5} from "ts-md5";

export function scrubName(name: string): string {
  name = name.replace(/[\n\t\r]/g, '');
  name = name.replace(/\s/g, '_');

  return name;
}

export function hashMd5(value: string) {
    return Md5.hashStr(value);
}

