import { Component, OnInit } from '@angular/core';
import {Access, AuthorizationService, Feature} from "../../authorization.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(private authorizationService: AuthorizationService) { }

  async ngOnInit() {
  }

  get hasSettingsAccess(): boolean {
    return this.authorizationService.hasAccess(Feature.SiteSettings, Access.Read);
  }
}
