import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AlertComponent} from './alert/alert.component';
import {lastValueFrom} from 'rxjs';
import {AlertSelectComponent} from "./alert-select/alert-select.component";
import {AlertPromptComponent} from "./alert-prompt/alert-prompt.component";
import {AlertConfirmComponent} from "./alert-confirm/alert-confirm.component";

export interface AlertData {
  title: string;
  message?: string;
  buttons?: string[];
}

export interface SelectAlertData extends AlertData {
    selections: {name: string, value: any}[];
}

export type AlertValidatorCallback = (value: string) => boolean;

export interface AlertPromptData extends AlertData {
  validator?: AlertValidatorCallback;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public dialog: MatDialog) { }

  async showError(err: Error) {
    const d = this.dialog.open(AlertComponent, {
        data: {
            alertType: 'error',
            title: err.name,
            message: err.message,
            stack: err.stack,
            link: err['link'],
            buttons: ['OK'],
        },
        panelClass: 'alert-panel-container',
    });

    await lastValueFrom(d.afterClosed());
  }

  async showSelection(data: SelectAlertData) {
      const d = this.dialog.open(AlertSelectComponent, {
          data,
          panelClass: 'alert-panel-container',
      });

      return await lastValueFrom(d.afterClosed());
  }

  async showPrompt(data: AlertPromptData) {
    const d = this.dialog.open(AlertPromptComponent, {
      data,
      panelClass: 'alert-panel-container',
    });

    return await lastValueFrom(d.afterClosed());
  }

  async confirm(data: AlertData) {
    const d = this.dialog.open(AlertConfirmComponent, {
      data,
      panelClass: 'alert-panel-container',
    });

    return await lastValueFrom(d.afterClosed());

  }
}

