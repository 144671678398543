
<div style="width:100%;height:100%">
  <amplify-authenticator [hideSignUp]="true" style="left: 50%; top: 50%; transform: translate(-50%, -50%); position: absolute">
    <ng-template
      amplifySlot="authenticated"
      let-signOut="signOut"
    >
      <button (click)="signOut()">Sign Out</button>
    </ng-template>
  </amplify-authenticator>
</div>
