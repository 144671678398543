<p>
  <button mat-button>Invite users</button>
  <mat-table [dataSource]="dataSource" matSort>
    <!-- game name Definition -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"><mat-icon></mat-icon><img ngSrc="{{row.iconUrl}}" class="small-icon"/> {{row.name}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</p>
