import { StorageMap } from '@ngx-pwa/local-storage';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storage: StorageMap) { }

  async get<T>(key: string): Promise<T> {
    return this.storage.get(key).toPromise().then(v => v as T);
  }

  set<T>(key: string, value: T): Observable<undefined> {
    console.log(`setting: ${key} to '${value}'`);
    return this.storage.set(key, value);
  }
}
