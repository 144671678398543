<mat-dialog-content>
  <h1 *ngIf="isCreateMode">Add Dashboard</h1>
  <h1 *ngIf="isEditMode">Edit Dashboard</h1>
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Dashboard Name</mat-label>
      <input matInput placeholder="Dashboard Name" type="text" [(ngModel)]="name">
      <mat-hint>A descriptive name-o</mat-hint>
      <button *ngIf="name" matSuffix mat-icon-button aria-label="Clear" (click)="name=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Dashboard Id</mat-label>
      <input matInput placeholder="ID" type="text" [(ngModel)]="dashboardId">
      <mat-hint>Quicksight dashboard id</mat-hint>
      <button *ngIf="dashboardId" matSuffix mat-icon-button aria-label="Clear" (click)="dashboardId=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="outline">
      <mat-label>Namespace</mat-label>
      <input matInput type="text" [(ngModel)]="namespace">
      <button *ngIf="namespace" matSuffix mat-icon-button aria-label="Clear" (click)="namespace=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </p>

</mat-dialog-content>
<mat-dialog-actions>
  <div style="display: flex; flex-direction: row; justify-content: space-evenly; width:100%">
    <button mat-flat-button *ngIf="isEditMode" (click)="remove()"   color="warn">Delete</button>
    <button mat-button (click)="cancel()" mat-flat-button>Cancel</button>
    <button mat-flat-button *ngIf="isCreateMode" (click)="create()" color="primary" [disabled]="!isCreateEnabled">Create</button>
    <button mat-flat-button *ngIf="isEditMode" (click)="save()"   color="primary" [disabled]="!isSaveEnabled">Save</button>
  </div>
</mat-dialog-actions>
