import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'editable-text-field',
  templateUrl: './editable-text-field.component.html',
  styleUrls: ['./editable-text-field.component.css']
})
export class EditableTextFieldComponent {
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
}
