<mat-dialog-content>
    <p>{{data.title}}</p>
    <mat-form-field>
        <mat-label>Value</mat-label>
        <mat-select (selectionChange)="onValueSelected($event.value)" [(value)]="selectedValue">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let item of data.selections" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
    <div fxFlex></div>
    <ng-container *ngFor="let button of buttons; index as i">
        <button mat-button (click)="action(i)" [disabled]="isButtonDisabled(i)">{{button}}</button>
        <div fxFlex></div>
    </ng-container>
</mat-dialog-actions>
