import {Component, Input, OnInit} from '@angular/core';
import {hashMd5} from "../../../data-model/utilities/experiments";

@Component({
  selector: 'app-portal-user-avatar',
  templateUrl: './portal-user-avatar.component.html',
  styleUrls: ['./portal-user-avatar.component.css']
})
export class PortalUserAvatarComponent implements OnInit {

  @Input() email: string;

  avatarSource: string;

  constructor() { }

  ngOnInit() {
    this.avatarSource = `https://www.gravatar.com/avatar/${hashMd5(this.email)}`;
  }
}
