import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-user-editor',
  templateUrl: './portal-user-editor.component.html',
  styleUrls: ['./portal-user-editor.component.css']
})
export class PortalUserEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
