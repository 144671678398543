import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface InfoLink {
  title: string;
  url: string;
}
export class AlertDialogData {
  alertType: 'error' | 'warning' | 'info';
  title: string;
  link?: InfoLink;
  stack?: string;
  message: string;
  buttons?: string[];
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  @HostListener('window:keyup.enter')
  @HostListener('window:keyup.esc') onKeyUp() {
    this.close(0);
  }

  constructor(private dialogRef: MatDialogRef<AlertComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  get buttons(): string[] {
    return this.data.buttons ? this.data.buttons : ['OK'];
  }

  close(buttonIndex: number) {
    this.dialogRef.close({buttonIndex});
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  get icon_name(): string {
    return this.data.alertType;
  }

  get stack(): string[] {
    return this.data.stack.split('\n');
  }
}
