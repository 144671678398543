import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectAlertData} from "../alert.service";

@Component({
  selector: 'app-alert-select',
  templateUrl: './alert-select.component.html',
  styleUrls: ['./alert-select.component.css']
})
export class AlertSelectComponent {
  buttons = ['Cancel', 'Select'];
  selectedValue: any = '';

  constructor(private dialogRef: MatDialogRef<AlertSelectComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SelectAlertData) {
    dialogRef.disableClose = true;
  }

  onValueSelected(value: any) {
    this.selectedValue = value;
  }

  isButtonDisabled(index: number) {
    if (index === 0) return false;

    return this.selectedValue === '';
  }

  action(index: number) {
    const result: any = {action: 'cancel'};

    if (index === 1) {
      result.action = 'select';
      result.selection = this.data.selections.find(
          v => v.value === this.selectedValue
      );
    }

    this.dialogRef.close(result);
  }
}
