import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {EditorState, EditorStateConfig, Extension, Text} from '@codemirror/state';
import {indentUnit, LanguageSupport} from '@codemirror/language';
import {basicSetup} from "codemirror";
import {EditorView, keymap, lineNumbers, Panel, showPanel, ViewUpdate} from '@codemirror/view';
import {indentWithTab} from "@codemirror/commands"
import {NG_VALUE_ACCESSOR} from "@angular/forms";

// Languages
import {javascript} from '@codemirror/lang-javascript';
import {sql} from '@codemirror/lang-sql';
import {python} from "@codemirror/lang-python";
import {json, jsonParseLinter} from '@codemirror/lang-json';
import {linter, lintGutter} from '@codemirror/lint';
import {DOCUMENT} from "@angular/common";

function countWords(state: EditorState) {
  const doc: Text = state.doc

  let count = 0, iter = doc.iter()
  while (!iter.next().done) {
    let inWord = false
    for (let i = 0; i < iter.value.length; i++) {
      let word = /\w/.test(iter.value[i])
      if (word && !inWord) count++
      inWord = word
    }
  }
  return `Word count: ${count}`
}

function wordCountPanel(view: EditorView): Panel {
  let dom = document.createElement("div")
  dom.textContent = countWords(view.state)
  dom.setAttribute('style', 'padding: 5px');
  return {
    dom,
    update(update) {
      if (update.docChanged) {
        dom.textContent = countWords(update.state);
      }
    }
  }
}

export function wordCounter() {
  return showPanel.of(wordCountPanel)
}

@Component({
  selector: 'code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CodeEditorComponent implements AfterViewInit {
  @Input() appendExtensions: Extension[] = [];
  @Input() language = 'sql';

  @Input()  content = '';
  @Output() contentChange = new EventEmitter<string>();
  @Input()  height: string;

  @ViewChild('myEditor') myEditor: any;

  editorView: EditorView;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    let lang: LanguageSupport;
    let lint: Extension;
    switch (this.language) {
      case 'sql':
        // lang = sql({dialect: PostgreSQL} );
        lang = sql();
        break;
      case 'javascript':
        lang = javascript({ typescript: false, jsx: false });
        break;
      case 'typescript':
        lang = javascript({ typescript: true, jsx: false });
        break;
      case 'python':
        lang = python();
        break;
      case 'json':
        lang = json();
        lint = linter(jsonParseLinter());
        break;
      default:
        throw new Error('Internal ngx-codemirror6 error - unrecognized language');
    }

    const fixedHeightEditor = EditorView.theme({
      "&": {height: this.height || "300px"},
      ".cm-scroller": {overflow: "auto"}
    });

    const tabSize = this.language === 'python' ? 4 : 2;
    const indentString = ' '.repeat(tabSize);

    const extensions: Extension[] = [
      lineNumbers(),
      lang,
      basicSetup,
      indentUnit.of(indentString),
      EditorState.readOnly.of(false),
      EditorState.tabSize.of(tabSize),
      keymap.of([indentWithTab]),
      // fixedHeightEditor,
      wordCounter(),

      ...this.appendExtensions,
      EditorView.updateListener.of((update: ViewUpdate) => {
        const content = update.state.doc.toString();

        if (content !== this.content) {
          //console.error(content);
          this.contentChange.emit(content);
        }
      }),
    ];

    if (lint) {
      extensions.push(lint);
      extensions.push(lintGutter());
    }

    this.codemirrorInit({
      doc: this.content,
      extensions: extensions,
    });
  }

  codemirrorInit(config: EditorStateConfig): void {
    this.editorView = new EditorView({
      parent: this.myEditor.nativeElement,
      state: EditorState.create(config),
    });

    this.editorView.requestMeasure();
  }
}
