import { Component, OnInit } from '@angular/core';
import {ApplicationService} from "../../../application.service";

@Component({
  selector: 'app-build-identifier',
  templateUrl: './build-identifier.component.html',
  styleUrls: ['./build-identifier.component.css']
})
export class BuildIdentifierComponent implements OnInit {

  constructor(private applicationService: ApplicationService) { }

  ngOnInit() {
  }

  get gitCommit(): string {
    return this.applicationService.getBuildConfig().shortSHA;
  }
}
