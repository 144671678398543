import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {UserService} from "../../services/user.service";


@Component({
  selector: 'app-portal-user-list',
  templateUrl: './portal-user-list.component.html',
  styleUrls: ['./portal-user-list.component.css']
})
export class PortalUserListComponent implements OnInit {
  loading = true;
  users: MatTableDataSource<any>;
  displayedColumns = ['avatar', 'name', 'created'];

  constructor(private userService: UserService) {
  }

  async ngOnInit() {
    const users = await this.userService.list();

    console.log(users);

    this.users = new MatTableDataSource(Object.keys(users));
    this.loading = false;
  }

  getUserAttribute(user, key): string {
    const {Attributes} = user;

    const {Value: value} = Attributes.find(a => a.Name === key);

    // console.log(`${key}: ${value}`);

    return value;
  }

  getUserName(user): string {
    return this.getUserAttribute(user, 'email');
  }

  getUserCreated(user): string {
    return user.UserCreateDate;
  }

  get usersAsString(): string {
    return JSON.stringify(this.users, null, 2);
  }

  editUser(user) {
    alert(JSON.stringify(user));
  }
}
