import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-small-icon-button',
  templateUrl: './small-icon-button.component.html',
  styleUrls: ['./small-icon-button.component.css']
})
export class SmallIconButtonComponent {
  @Input() icon_name = 'play_circle_filled';
}
