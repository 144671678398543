import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Router} from "@angular/router";
import {AuthorizationService} from "../../../authorization.service";
import {createJiraUrl} from "../../../data-model/utilities/jira";


@Component({
  selector: 'file-ticket-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './file-ticket-button.component.html',
  styleUrls: ['./file-ticket-button.component.scss']
})
export class FileTicketButtonComponent {
  @Input() projectId: string;
  @Input() issueType: string;
  @Input() summary: string;
  @Input() description: string;

  constructor(private router: Router,
              private authorizationService: AuthorizationService) {

  }

  createTicket() {
    const description = `
[YOUR DESCRIPTION HERE]

=============================Context Info=============================
${this.description || ''}
Source url: ${window.location}
${window.navigator.userAgent}
    `;

    const parameters = {
      description,
      summary:  this.summary || 'Atlas Feedback: [INSERT TITLE]',
      reporter: this.authorizationService.userAttributes.email,
      labels: 'AtlasFeedback',
      issuetype: this.issueType,
      pid: this.projectId,
      priority: 10100,
    };

    const url = createJiraUrl(parameters);
    //const url = `https://wildcardgames.atlassian.net/secure/CreateIssue.jspa?issueType=${this.issueType}&pid=${this.projectId}&description=foobar`;
    console.log(url);
    window.open(url, '_blank');
  }

}
