<mat-card appearance="outlined">
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Where to move?</mat-label>
      <mat-select [(value)]="selectedDomain">
        <mat-option *ngFor="let domain of domainList" [value]="domain.value">{{domain.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </p>

  <div style="text-align: center" fxLayout="row">
    <div fxFlex></div>
    <button mat-button (click)="cancel()" mat-flat-button>Cancel</button>
    <div fxFlex></div>
    <button mat-flat-button (click)="move()" [disabled]="!selectedDomain" color="primary">Move</button>
    <div fxFlex></div>
  </div>
</mat-card>
