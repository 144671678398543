import {Injectable} from '@angular/core';
import {Athena, QueryExecution, QueryExecutionState} from '@aws-sdk/client-athena';
import {AuthorizationService} from "../../authorization.service";
import {StartQueryExecutionCommandOutput} from "@aws-sdk/client-athena/dist-types/commands/StartQueryExecutionCommand";
import {DashboardApiService} from "../../services/dashboard-api.service";
import {Utilities} from "../../../../wildcard-dashboard-common/data-model/utilities";

const COMPLETED_QUERY_STATES : string[] = [
  QueryExecutionState.FAILED,
  QueryExecutionState.CANCELLED,
  QueryExecutionState.SUCCEEDED
];

export interface Record {
  [name: string]: any;
}

export type Records = Record[];

export interface AthenaQueryResult {
  data: AthenaQueryData;
}
export interface AthenaQueryData {
  header: string[];
  rows: string[][];
}

function recordsFromAthenaResult(result: AthenaQueryData) {
  const {header, rows} = result;

  const records: Records = rows.map(row => {
    const record = {};
    header.forEach((value, index) => record[value] = row[index]);
    return record;
  });

  return records as Records;
}

export class AthenaQueryResult {
  private athenaService: AthenaService;
  private startQueryResult: StartQueryExecutionCommandOutput;
  private queryExecution: QueryExecution;
  private records: Records;

  constructor(athenaService: AthenaService, startQueryResult: StartQueryExecutionCommandOutput) {
    this.athenaService = athenaService;
    this.startQueryResult = startQueryResult;
  }

  get queryExecutionId(): string {return this.startQueryResult.QueryExecutionId; }
  get queryState(): QueryExecutionState { return this.queryExecution.Status.State; }

  get isComplete(): boolean {
    return this.queryExecution && COMPLETED_QUERY_STATES.includes(this.queryExecution.Status.State);
  }

  get isSuccess(): boolean {
    return this.queryExecution && this.queryExecution.Status.State === 'SUCCEEDED';
  }

  async waitForCompletion() {
    if (!this.isComplete) {
      console.log('waitForCompletion');
      while (true) {
        this.queryExecution = await this.athenaService.updateQuery(this.queryExecutionId);

        if (this.isComplete) {
          break;
        }

        await Utilities.sleep(250);
      }

      if (!this.isSuccess) {
        console.error(JSON.stringify(this.queryExecution.Status));
      }
    }

    return this.queryExecution.Status.State;
  }

  async getResult() {
    if (!this.records) {
      console.log('getResult');
      if (this.queryState !== QueryExecutionState.SUCCEEDED) {
        throw new Error(`No data is available in athena query state ${this.queryState}`);
      }

      const result = await this.athenaService.fetchQuery(this.queryExecutionId);

      this.records = recordsFromAthenaResult(result);

      console.log(this.records);
    }

    return this.records;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AthenaService {
  athena: Athena;

  constructor(private authorizationService: AuthorizationService,
              private dashboardApi: DashboardApiService) {
  }

  async startQuery(query: string, allowCache = false) {
    const result = await this.dashboardApi.athena.post<StartQueryExecutionCommandOutput>('/startQuery', {body: {sql: query}});

    return new AthenaQueryResult(this, result);
  }

  async updateQuery(queryExecutionId: string) {
    return await this.dashboardApi.athena.get<QueryExecution>(`/updateQuery?queryExecutionId=${queryExecutionId}`);
  }

  async fetchQuery(queryExecutionId: string) {
    const result = await this.dashboardApi.athena.get<AthenaQueryResult>(`/fetchQuery?queryExecutionId=${queryExecutionId}`);

    return result.data;
  }
}
