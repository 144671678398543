import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { PublicComponent } from './layouts/public/public.component';

import { DashboardComponent} from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth-guard';
import {SettingsComponent} from './settings/settings.component';
import {NavigationComponent} from './layouts/navigation/navigation.component';
import {PortalUserListComponent} from "./authorization/portal-user-list/portal-user-list.component";
import {PortalUserEditorComponent} from "./authorization/portal-user-editor/portal-user-editor.component";
import {PageNotFoundComponent} from './modules/common/page-not-found/page-not-found.component';

const PUBLIC_ROUTES: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
];

const SECURE_ROUTES: Routes = [
  { path: '',                   component: DashboardComponent },
  { path: 'games',              loadChildren: () => import('./modules/games/games.module').then(m => m.GamesModule) },
  { path: 'data',               loadChildren: () => import('./modules/data/data.module').then(m => m.DataModule) },
  { path: 'growth',             loadChildren: () => import('./modules/growth/growth.module').then(m => m.GrowthModule) },
  { path: 'finance',            loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule)  },
  { path: 'wildcloud',          loadChildren: () => import('./modules/wildcloud/wildcloud.module').then(m => m.WildcloudModule)  },
  { path: 'settings',           component: SettingsComponent },
  { path: 'users',              component: PortalUserListComponent },
  { path: 'users/:userId',      component: PortalUserEditorComponent },
];

const routes: Routes = [
  { path: '', component: NavigationComponent, canActivate: [AuthGuard], data: { title: 'Secure Views' }, children: SECURE_ROUTES },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: PublicComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },

  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
