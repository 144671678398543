import {Component, HostListener, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {AlertDialogData} from "../alert/alert.component";

@Component({
  selector: 'alert-confirm',
  standalone: true,
  imports: [CommonModule, FlexModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss']
})
export class AlertConfirmComponent implements OnInit {
  icon_name = 'warning';
  buttons: string[];

  @HostListener('keyup', ["$event"]) onKeyUp(event: any) {
    console.log(event);
    if (event.key === "Escape") {
      event.stopPropagation();
      this.dialogRef.close(false);
    } else if (event.key === "Enter") {
      event.stopPropagation();
      this.dialogRef.close(true);
    }
  }

  @HostListener('keydown', ["$event"]) onKeyDown(event: any) {
    if (event.key === "Enter") {
      event.stopPropagation();
    }
  }

  constructor(private dialogRef: MatDialogRef<AlertConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.buttons = this.data.buttons || ['Cancel', 'Confirm'];
  }

  color(i: number) {
    return (i === 1) ? 'primary' : '';
  }

  close(index: number) {
    this.dialogRef.close(index === 1);
  }
}
