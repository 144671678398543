import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

export class ProgressDialogData {
  title: string;
}

export class ProgressUpdateData {
  progress: number;
  message: string;
}

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.css']
})
export class ProgressDialogComponent implements OnInit {

  title: string;
  progressString: string;
  value: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProgressDialogData,
              public dialogRef: MatDialogRef<ProgressDialogComponent>) {

    dialogRef.disableClose = true;
  }

  ngOnInit() {
    console.log(this.data);
    this.title = this.data.title;
  }

  update(progressUpdate: ProgressUpdateData) {
    // console.log('update');

    this.value = progressUpdate.progress * 100;
    this.progressString = progressUpdate.message;
  }
}

type ProgressDialogHandlers<T> = {
  onNext?: (data: T, dialogInstance: ProgressDialogComponent) => void,
  onError?: (e) => void,
  onCompleted?: () => void
};

export function showProgress(operation: Observable<ProgressUpdateData>, title: string, dialog: MatDialog, handlers?: Pick<ProgressDialogHandlers<ProgressUpdateData>, 'onError' | 'onCompleted'>) {
  showProgressForOperation(operation, title, dialog, {
    ...handlers,
    onNext: (data, dialogInstance) => dialogInstance.update(data)
  });
}

export function showProgressForOperation<T>(operation: Observable<T>, title: string, dialog: MatDialog, handlers?: ProgressDialogHandlers<T>) {
  const dialogRef = dialog.open(ProgressDialogComponent, {
    width: '600px',
    data: {title}
  });

  if (!handlers) { handlers = {}; }
  const {onNext, onError, onCompleted} = handlers;

  operation.subscribe((data) => {
    if (onNext) { onNext(data, dialogRef.componentInstance); }
  }, err => {
    console.error(err);
    alert(`error: ${JSON.stringify(err)}`);
    dialogRef.close();
    if (onError) { onError(err); }
  }, () => {
    dialogRef.close();
    if (onCompleted) { onCompleted(); }
  });
}
