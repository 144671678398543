import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-move-dashboard-dialog',
  templateUrl: './move-dashboard-dialog.component.html',
  styleUrls: ['./move-dashboard-dialog.component.css']
})
export class MoveDashboardDialogComponent implements OnInit {

  domainList: any[] = [];
  selectedDomain: any;

  constructor(private dialogRef: MatDialogRef<MoveDashboardDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {name: string, value: string}[]) { }

  ngOnInit(): void {
    this.domainList = this.data;
  }

  async cancel() {
    this.dialogRef.close();
  }

  async move() {
    this.dialogRef.close({domain: this.selectedDomain});
  }
}
