import { Injectable } from '@angular/core';
import {
  QueryCommand,
  GetCommand,
  PutCommand,
  DeleteCommand,
  UpdateCommand
} from '@aws-sdk/lib-dynamodb';
import {Utilities} from '../../../wildcard-dashboard-common/data-model/utilities';
import {WildCloudService} from "../wild-cloud/wild-cloud.service";
import {DynamodbService} from "./dynamodb.service";
import {DashboardApiService} from "./dashboard-api.service";
import {AuthorizationService} from "../authorization.service";


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private wildCloud: WildCloudService,
              private dynamodbService: DynamodbService,
              private dashboardApiService: DashboardApiService,
              private authorizationService: AuthorizationService) { }

  async listDashboards(domain: string) {
    const client = await this.dynamodbService.getClient();

    const query = new QueryCommand({
      TableName: 'dashboards',
      IndexName: 'domain-index',
      KeyConditionExpression: '#domain = :domain',
      ExpressionAttributeNames: {
        '#domain': 'domain',
      },
      ExpressionAttributeValues: {
        ':domain': domain,
      }
    });

    const {Items} = await client.send(query);

    console.log(Items);

    return Items;
  }

  async getDashboard(domain: string, dashboardId: string) {
    console.log('getDashboard', domain, dashboardId);

    const client = await this.dynamodbService.getClient();
    const get = new GetCommand({
      TableName: 'dashboards',
      Key: {domain, dashboardId},
    });

    const {Item} = await client.send(get);

    console.log(Item);

    return Item;
  }

  async getQuicksightDashboard(quicksightNamespace: string, dashboardId: string) {
    const result = await this.dashboardApiService.quicksightDashboard.get<any>(
        `/${quicksightNamespace}/dashboard/${dashboardId}`,
      {}
    );

    // console.log(result);

    return result.EmbedUrl;
  }

  async createDashboard(request: {domain: string, namespace: string, name: string, dashboardId: string}) {
    // ensure the dashboard actually exists
    await this.getQuicksightDashboard(request.namespace, request.dashboardId);

    // Let's create the record!
    const user = this.authorizationService.currentAuthenticatedUser;
    const client = await this.dynamodbService.getClient();

    const timestamp = Utilities.getTimeStamp();
    console.log(user);

    const userId = user.userId;

    console.log(`userId: ${userId}`);

    const item = {
      creationDate: timestamp,
      lastModified: timestamp,
      createdBy: userId,
      modifiedBy: userId,
    };

    Object.assign(item, request);

    const put = new PutCommand({
      TableName: 'dashboards',
      Item: item,
    });

    await client.send(put);
  }

  async deleteDashboard(dashboard) {
    const client = await this.dynamodbService.getClient();

    const {dashboardId, domain} = dashboard;
    const del = new DeleteCommand({
      TableName: 'dashboards',
      Key: { dashboardId, domain }
    });

    await client.send(del);
  }

  async moveDashboard(dashboard, domain: string) {
    const newDashboard: any = {};

    Object.assign(newDashboard, dashboard);
    newDashboard.domain = domain;

    await this.deleteDashboard(dashboard);
    await this.createDashboard(newDashboard);
  }

  async updateDashboard(dashboard) {
    const user = this.authorizationService.currentAuthenticatedUser;
    const {userId} = user;

    const client = await this.dynamodbService.getClient();
    const timestamp = Utilities.getTimeStamp();

    const {dashboardId, domain, name, namespace} = dashboard;

    console.log(`Updating dashboard: ${dashboardId}:${domain} => ${name}`);

    const update = new UpdateCommand({
      TableName: 'dashboards',
      Key: {dashboardId, domain},
      UpdateExpression: 'set #name = :name, #namespace = :namespace, lastModified = :lastModified, modifiedBy = :modifiedBy',
      ExpressionAttributeNames: {
        '#name': 'name',
        '#namespace': 'namespace',
      },
      ExpressionAttributeValues: {
        ':name': name,
        ':lastModified': timestamp,
        ':modifiedBy': userId,
        ':namespace': namespace,
      },
    });

    await client.send(update);
  }

  async getDomainList(env: string) {
    const domainList = [
      {name: 'Data',    value: 'data'},
      {name: 'Growth',  value: 'growth'},
      {name: 'Finance', value: 'finance'},
    ];

    const apps = (await this.wildCloud.listApplications()).items;
    for (const app of apps) {
      domainList.push({
        name: app.name,
        value: app.id,
      });
    }

    if (env !== 'live') {
      for (const domain of domainList) {
        domain.value += '_' + env;
      }
    }

    return domainList;
  }
}
