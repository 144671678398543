<div matSort *ngIf="isReady">
    <div style="display: flex; flex-direction: row; align-items: center; padding: 10px">
        <div><h2>Dashboards</h2></div>

        <div style="flex-grow: 1"></div>

        <button mat-icon-button (click)="addNewDashboard()" matTooltip="Add existing dash">
            <mat-icon>add</mat-icon>
        </button>

        <button mat-flat-button (click)="openQuicksight()" matTooltip="Open Quicksight">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </div>
    <div *ngIf="dashboards.length > 0">
        <mat-card appearance="outlined" *ngFor="let dashboard of dashboards"
                  (click)="showDashboard(dashboard)"
                  class="dashboard-entry">
            <mat-icon style="margin-left: 15px; margin-right: 5px;">dashboard</mat-icon>
            {{ dashboard.name }}
            <div fxFlex></div>
            <button mat-icon-button [matMenuTriggerFor]="appMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #appMenu="matMenu">
                <button mat-menu-item (click)="viewActivity(dashboard)">
                    <mat-icon>visibility</mat-icon>
                    View Activity
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="editDashboard(dashboard)">
                    <mat-icon>edit</mat-icon>
                    Edit Dashboard
                </button>
                <button mat-menu-item (click)="moveDashboard(dashboard)">
                    <mat-icon>input</mat-icon>
                    Move
                </button>
            </mat-menu>
        </mat-card>
    </div>
    <div *ngIf="dashboards.length === 0" style="text-align: center; font-style: italic">
        No dashboards defined
    </div>
</div>

<app-spinner *ngIf="!isReady"></app-spinner>
