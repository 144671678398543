import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RuleDefinition, RuleFilter, RuleGroup, RuleOperatorNames, SupportedRules} from '../../../data-model/rules';
import iso3166a2 from 'iso-3166-1-alpha-2';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-rule-editor',
  templateUrl: './rule-editor.component.html',
  styleUrls: ['./rule-editor.component.css']
})
export class RuleEditorComponent implements OnInit {

  @Input() readOnly = false;
  @Input() groups: RuleGroup[];
  @Output() changed = new EventEmitter();

  // TODO: extract country picker into a separate component. It's a bit messy to keep in RuleEditor
  public countriesFilterCtrl: UntypedFormControl = new UntypedFormControl('');
  public countriesMultiCtrl: UntypedFormControl = new UntypedFormControl([]);

  constructor() { }

  ngOnInit() {
    this.setInitialCountryList();

    if (this.readOnly) {
      this.countriesMultiCtrl.disable();
    }
  }

  private setInitialCountryList() {
    for (const group of this.groups) {
      for (const filter of group.filters) {
        const rule = this.getRuleById(filter.ruleId);
        if (rule.type === 'countryList') { this.countriesMultiCtrl.setValue(filter.value); return; }
      }
    }
  }

  setFilterRuleId(filter: RuleFilter, ruleId) {
    const rule = this.getRuleById(ruleId);

    filter.ruleId = ruleId;
    filter.operator = rule.operators[0];

    delete filter.value;

    if (rule.type === 'countryList') { this.countriesMultiCtrl.setValue([]); }

    this.valueChanged();
  }

  setFilterOperator(filter: RuleFilter, operator: string) {
    filter.operator = operator;
    this.valueChanged();
  }

  setFilterValue(filter: RuleFilter, value: any) {
    filter.value = value;
    this.valueChanged();
  }

  ruleHasOperators(ruleId) {
    const rule = this.getRuleById(ruleId);

    // console.log(ruleId);

    return rule.operators && rule.operators.length > 0;
  }

  ruleHasValues(ruleId) {
    const rule = this.getRuleById(ruleId);

    // console.log(ruleId);

    return rule.values && rule.values.length > 0;
  }

  getCountryCodes() {
    return iso3166a2.getCodes().filter(code => {
      const name = iso3166a2.getCountry(code);
      return name.toLowerCase().indexOf(this.countriesFilterCtrl.value.toLowerCase()) > -1;
    });
  }

  getCountryName(code: string) {
    return iso3166a2.getCountry(code);
  }

  wrapInArrayIfNeeded(value: string | string[]) {
    if (Array.isArray(value)) { return value; }

    return [value];
  }

  typeOfRule(ruleId, type) {
    const rule = this.getRuleById(ruleId);

    return rule.type === type;
  }

  addGroup() {
    const group = new RuleGroup();

    this.addFilter(group);

    if (!this.groups) {
      this.groups = [];
    }

    this.groups.push(group);
    this.changed.emit();
  }

  addFilter(group: RuleGroup) {
    const filter = new RuleFilter();

    filter.ruleId = SupportedRules[0].id;
    filter.operator = SupportedRules[0].operators[0];

    group.filters.push(filter);
    this.changed.emit();
  }

  deleteFilter(group: RuleGroup, filter: RuleFilter) {
    if (group.filters.length === 1) {
      // remove from groups
      const index = this.groups.indexOf(group);
      this.groups.splice(index, 1);
    } else if (group.filters.length > 1) {
      // remove just the one filter
      const index = group.filters.indexOf(filter);
      group.filters.splice(index, 1);
    }

    this.changed.emit();
  }

  getRuleById(ruleId): RuleDefinition {
    return SupportedRules.find( rule => rule.id === ruleId);
  }

  operatorsForRule(ruleId) {
    return this.getRuleById(ruleId).operators;
  }

  valuesForRule(ruleId) {
    return this.getRuleById(ruleId).values;
  }

  nameForOperator(operatorId) {
    return RuleOperatorNames[operatorId];
  }

  get rules(): RuleDefinition[] {
    return SupportedRules;
  }

  valueChanged(): void {
    this.changed.emit();

    console.warn(JSON.stringify(this.groups));
  }

  datePickerValueChanged(filter: RuleFilter): void {
    let value: string = filter.value.toISOString();

    value = value.substring(0, value.indexOf('T'));
    value += 'T00:00:00.000Z';

    filter.value = value;

    this.valueChanged();
  }

  isLastFilterInGroup(group: RuleGroup, filter: RuleFilter) {
    const idx = group.filters.findIndex(f => f === filter);

    return idx === group.filters.length - 1;
  }
}
