import { Component, OnInit } from '@angular/core';
import {CommonModule} from "@angular/common";
import {QuicksightDashboardComponent} from "../modules/dashboards/quicksight-dashboard/quicksight-dashboard.component";

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  imports: [CommonModule, QuicksightDashboardComponent]
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
