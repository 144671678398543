const normalizeBundleVersion = (bundleVersion) => {
    const components = bundleVersion.split('.');

    while (components.length < 3) {
        components.push('0');
    }

    while (components.length > 3) {
        components.pop();
    }

    for (let i = 0; i < components.length; i++) {
        while (components[i].length < 4) {
            components[i] = '0' + components[i];
        }
    }

    return components.join('');
};

const normalizeStringList = (list) => {
    let value = list;

    if (!Array.isArray(list)) {
        value = JSON.parse(value);
    }

    return value;
};

const MatchRule = (filter, context) => {
    const rule = SupportedRules.find(r => r.id === filter.ruleId);

    if (!rule) {
        throw new Error(`Unsupported ruleId "${filter.ruleId}"`);
    }

    if (!context.variables[filter.ruleId]) {
        throw new Error(`Variable "${filter.ruleId}" not found in context`);
    }

    if (rule.operators.indexOf(filter.operator) === -1) {
        throw new Error(`Operator "${filter.operator}" not found in allowed operators`);
    }

    let testValue = filter.value;
    let currentValue = context.variables[filter.ruleId];

    if (rule.normalizeCondition) {
        testValue = rule.normalizeCondition(testValue);
    }

    if (rule.normalizeCurrentValue) {
        currentValue = rule.normalizeCurrentValue(currentValue);
    }

    const operator = RuleOperators[filter.operator];

    if (!operator) {
        throw new Error(`Could not find operator "${filter.operator}"`);
    }

    return operator(currentValue, testValue);
};

const OperatorGTE = (currentValue, testValue) => currentValue >= testValue;
const OperatorLTE = (currentValue, testValue) => currentValue <= testValue;
const OperatorGT = (currentValue, testValue) => currentValue > testValue;
const OperatorLT = (currentValue, testValue) => currentValue < testValue;
const OperatorEQ = (currentValue, testValue) => currentValue === testValue;
const OperatorContains = (currentValue, testValue) => currentValue.indexOf(testValue) >= 0;
const OperatorStartsWith = (currentValue, testValue) => currentValue.indexOf(testValue) === 0;
const OperatorIn = (currentValue, testValues) => testValues.indexOf(currentValue) >= 0;
const OperatorNotIn = (currentValue, testValues) => !OperatorIn(currentValue, testValues);

const RuleOperators = {
    gte: OperatorGTE,
    lte: OperatorLTE,
    gt: OperatorGT,
    lt: OperatorLT,
    eq: OperatorEQ,
    contains: OperatorContains,
    startsWith: OperatorStartsWith,
    in: OperatorIn,
    notIn: OperatorNotIn,
} as const;

export class RuleDefinition {
    name: string;
    type: string;
    id: string;
    operators: (keyof typeof RuleOperators)[];
    normalizeCurrentValue?: any = null;
    normalizeCondition?: any = null;
    values?: any[] = null;
}

export const SupportedRules: RuleDefinition[] = [
    {
        name: 'First login (date)',
        type: 'date',
        id: 'firstLogin',
        operators: ['gte', 'lte', 'gt', 'lt']
    },
    {
        name: 'New User',
        type: 'bool',
        id: 'newUser',
        operators: ['eq'],
    },
    {
        name: 'Ad Campaign Attribution',
        type: 'string',
        id: 'attribution',
        operators: ['eq', 'contains', 'startsWith']
    },
    {
        name: 'Bundle Version',
        type: 'bundleVersion',
        id: 'bundleVersion',
        operators: ['gte', 'eq', 'gt'],
        normalizeCurrentValue: normalizeBundleVersion,
        normalizeCondition: normalizeBundleVersion,
    },
    {
        name: 'Country',
        type: 'countryList',
        id: 'country',
        operators: ['in', 'notIn'],
        normalizeCondition: normalizeStringList,
    },
    {
        name: 'Platform',
        type: 'stringSet',
        id: 'platform',
        operators: ['eq'],
        values: ['iOS', 'Android'],
    },
    {
        name: 'System Memory',
        type: 'number',
        id: 'systemMemory',
        operators: ['gte', 'lte', 'gt', 'lt'],
    },
];


export class RuleGroup {
    filters: RuleFilter[] = [];

    static summary(group: RuleGroup): string {
        let summaryString = '';
        for (let i = 0; i < group.filters.length; i++) {
            const filter = group.filters[i];

            if (i > 0) {
                summaryString += ' AND ';
            }

            summaryString += RuleFilter.summary(filter);
        }

        return summaryString;
    }
}

export class RuleFilter {
    ruleId: string;
    operator: string;
    value: any;

    static summary(filter: RuleFilter): string {
        return `(${filter.ruleId} ${filter.operator} ${filter.value})`;
    }
}

export class RuleEngineContext {
    variables = {};
}

export class RuleEngine {
    static evaluate(context: RuleEngineContext, groups: RuleGroup[]) {
        // groups are implicitly 'or', so any matching will suffice

        console.log('**************************************');
        console.log(context);
        console.log(groups);

        for (const group of groups) {
            console.log('+++++++++++++++++++++');
            let matches = true;

            for (const filter of group.filters) {
                matches = matches && MatchRule(filter, context);

                console.log('-----------------');
                console.log(`matches: ${matches}`);
                console.log(filter);
                console.log(context);
                console.log('-----------------');

                if (!matches) {
                    break;
                }
            }

            if (matches) {
                return true;
            }
        }

        return false;
    }

    static summary(groups: RuleGroup[]) {
        if (groups.length === 0) {
            return 'No rules specified';
        } else {
            let summaryString = '';
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];

                const temp = RuleGroup.summary(group);
                if (i > 0) {
                    summaryString += ` OR ${temp}.`;
                } else {
                    summaryString += `${temp}.`;
                }
            }
            return summaryString;
        }
    }
}

export const RuleOperatorNames = {
    gte: 'Greater Than or Equal',
    lte: 'Less Than or Equal',
    gt: 'Greater Than',
    lt: 'Less Than',
    eq: 'Equals',
    contains: 'Contains',
    startsWith: 'Starts With',
    in: 'In',
    notIn: 'Not In',
} as const;
